<template>
    <div class="uk-margin-top">
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-5"></div>
            <div class="uk-width-1-5"></div>
            <div class="uk-width-1-5"></div>
            <template v-if="reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
                <template v-if="cancelReorderCategoryListButton || saveReorderCategoryListButton">
                    <div class="uk-width-1-5"></div>
                    <div class="uk-width-1-5">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-width-1-1"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="uk-width-1-5">
                        <button 
                            type="button" 
                            class="uk-button uk-button-default uk-width-1-1"
                            @click="cancelReorderCategoryList"
                        >Cancel</button>
                    </div>
                    <div class="uk-width-1-5">
                        <button 
                            type="button" 
                            class="uk-button uk-width-1-1"
                            :class="[onMoveCategoryItem === true ? 'uk-button-default' : 'uk-button-primary']"
                            :disabled="onMoveCategoryItem === true"
                            @click="saveReorderCategoryList"
                        >Save Changes</button>
                    </div>
                </template>
            </template>
            <template v-else-if="!reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
                <div class="uk-width-1-5"></div>
                <div class="uk-width-1-5">
                    <button 
                        type="button" 
                        class="uk-button uk-button-primary uk-width-1-1"
                        @click="enableReorderMode({ value: true })"
                    >Reorder</button>
                </div>
            </template>
        </div>

        <div class="uk-card uk-card-default uk-margin-top">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-shrink">No</th>
                            <th>Name</th>
                            <th v-if="reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center"></th>
                            <th v-else-if="!reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading v-if="categoryListTableLoading" :colspan="3"></loading>
                    <template v-else>
                        <template v-if="reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
                            <draggable 
                                v-if="categoryData.docs.length > 0" 
                                v-model="categoryData.docs" 
                                tag="tbody"
                                @change="onChange"
                            >
                                <tr 
                                    v-for="(category, index) in categoryData.docs" 
                                    :key="index" 
                                    :draggable="reorderMode" 
                                    :class="[reorderMode === true ? 'box' : '']"
                                >
                                    <td class="uk-table-shrink">
                                        {{ autoIncrementNumberPagination({ 
                                            pagingCounter: categoryData.pagingCounter,
                                            index 
                                        }) }}
                                    </td>
                                    <td>{{ category.name || '-' }}</td>
                                    <td class="uk-width-small uk-text-center">
                                        <img :src="`${images}/arrow-bidirectional-up-down-12-filled.svg`" alt="Drag drop icon" class="drag-drop-icon">
                                    </td>
                                </tr>
                            </draggable>
                            <empty-table v-else :colspan="3" />
                        </template>
                        <template v-else-if="!reorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
                            <tbody v-if="categoryData.docs.length > 0">
                                <tr 
                                    v-for="(category, index) in categoryData.docs" 
                                    :key="index" 
                                    :draggable="reorderMode" 
                                    :class="[reorderMode === true ? 'box' : '']"
                                >
                                    <td class="uk-table-shrink">
                                        {{ autoIncrementNumberPagination({ 
                                            pagingCounter: categoryData.pagingCounter,
                                            index 
                                        }) }}
                                    </td>
                                    <td>{{ category.name || '-' }}</td>
                                    <td v-if="consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center">
                                        <button 
                                            type="button" 
                                            class="uk-button uk-button-small uk-button-default"
                                        >Actions</button>
                                        <div uk-dropdown="mode: click">
                                            <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                <li><a href="#" @click="editCategory({ data: category })">Edit</a></li>
                                                <li class="uk-nav-divider"></li>
                                                <li><a href="#" @click="deleteCategory({ data: category })">Delete</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <empty-table v-else :colspan="3" />
                        </template>
                    </template>
                </table>
            </div>
            <pagination
                :total-data="categoryData.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
                :disabled-change-page-action="disabledChangePageAction"
            ></pagination>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import draggable from "vuedraggable";
import { getUserLogin } from '@/utils/auth';
import formatter from '@/utils/formatter';

export default {
    name: 'CategoryTab',
    components: {
        draggable,
        Loading: () => import('@/components/globals/tables/Loading'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable')
    },
    props: {
        disabledChangePageAction: {
            type: Boolean,
            required: false,
            default: false
        },
        categoryListTableLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        cancelReorderCategoryListButton: {
            type: Boolean,
            required: false,
            default: false
        },
        saveReorderCategoryListButton: {
            type: Boolean,
            required: false,
            default: false
        },
        reorderMode: {
            type: Boolean,
            required: false,
            default: false
        },
        categoryData: {
            type: Object,
            required: false,
            default() {
                return {
                    docs: [],
                    totalDocs: 0,
                    pagingCounter: 0,
                    meta: {
                        limit: 50,
                        page: 1,
                        name: ''
                    }
                };
            }
        }
    },
    data() {
        return {
            user_cred: getUserLogin(),
            images: PREFIX_IMAGE,
            payloadReorderCategoryList: {
                paging_counter: 0,
                job_preference_category_ids: []
            },
            onMoveCategoryItem: true,
            meta: {
                limit: 50,
                page: 1,
                totalData: 0
            }
        };
    },
    methods: {
        enableReorderMode() {
            this.onMoveCategoryItem = true;
            this.resetPayloadReorderCategoryList();
            this.hideDropdown();
            this.$emit('enable-reorder-mode');
        },
        cancelReorderCategoryList() {
            this.onMoveCategoryItem = true;
            this.resetPayloadReorderCategoryList();
            this.hideDropdown();
            this.$emit('cancel-reorder-category-list');
        },
        onChange(event) {
            this.onMoveCategoryItem = false;
            
            if (event && event.moved) {
                this.resetPayloadReorderCategoryList();
                this.fillPayloadReorderCategoryList({ pagingCounter: this.categoryData.pagingCounter, categoryList: this.categoryData.docs });
            }
        },
        saveReorderCategoryList() {
            this.$emit('save-reorder-category-list', this.payloadReorderCategoryList);
        },
        editCategory({ data }) {
            this.hideDropdown();
            this.$emit('edit-category', data);
        },
        deleteCategory({ data }) {
            this.hideDropdown();
            this.$emit('delete-category', data);
        },
        fillPayloadReorderCategoryList({ pagingCounter, categoryList }) {
            this.payloadReorderCategoryList.paging_counter = pagingCounter;
            if (categoryList && categoryList.length > 0) {
                const distinctJobPreferenceCategoryIds = [...new Set(categoryList.map(item => String(item._id)))];
                this.payloadReorderCategoryList.job_preference_category_ids = distinctJobPreferenceCategoryIds;
            } else {
                this.payloadReorderCategoryList.job_preference_category_ids = [];
            }
        },
        resetPayloadReorderCategoryList() {
            this.payloadReorderCategoryList.paging_counter = 0;
            this.payloadReorderCategoryList.job_preference_category_ids = [];
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
            this.$emit('change-limit', e);
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
            this.$emit('change-page', value);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        }
    }
};
</script>

<style scoped>
.box {
    background: #FFFFFF;
    cursor: move;
}
.drag-drop-icon {
    width: 31px;
    height: 31px;
}
</style>
